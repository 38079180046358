const FrontText = ({
    textSize,
    className = "",
    spacings = false,
    strongSemiBold = false,
    strongColor = false,
    customLinkStyles = false,
    children,
}) => {
    /* =================== [Font Size] =================== */
    let fontSize = "";

    switch (textSize) {
        case "96/109":
            fontSize = "text-96 leading-c109";
            break;
        case "40/46":
            fontSize = "text-40 leading-c46";
            break;
        case "38/46":
            fontSize = "text-38 leading-c46";
            break;
        case "38/46-m-32/44":
            fontSize = "md:text-38 md:leading-c46 text-32 leading-11";
            break;
        case "38/43":
            fontSize = "text-38 leading-c43";
            break;
        case "38/43-m-24/27":
            fontSize = "md:text-38 md:leading-c43 text-24 leading-c27";
            break;
        case "37/44-m-23/28":
            fontSize = "md:text-37 md:leading-11 text-23 leading-7";
            break;
        case "32/28-m-28/32":
            fontSize = "md:text-32 md:leading-7 text-28 leading-8";
            break;
        case "32/39":
            fontSize = "text-32 leading-c39";
            break;
        case "32/39-m-28/36":
            fontSize = "md:text-32 md:leading-c39 leading-9 text-28";
            break;
        case "32/36":
            fontSize = "text-32 leading-9";
            break;
        case "32/36-m-28/32":
            fontSize = "md:text-32 md:leading-9 text-28 leading-8";
            break;
        case "32/36-m-20/23":
            fontSize = "md:text-32 md:leading-9 text-20 leading-c23";
            break;
        case "28/34":
            fontSize = "text-28 leading-c34";
            break;
        case "28/32":
            fontSize = "text-28 leading-8";
            break;
        case "25/30":
            fontSize = "text-25 leading-c30";
            break;
        case "24/29":
            fontSize = "text-24 leading-c29";
            break;
        case "24/27":
            fontSize = "text-24 leading-c27";
            break;
        case "20/29":
            fontSize = "text-20 leading-c29";
            break;
        case "20/29-m-14/20":
            fontSize = "md:text-20 md:leading-c29 text-14 leading-5";
            break;
        case "20/25":
            fontSize = "text-20 leading-c25";
        case "20/24":
            fontSize = "text-20 leading-6";
            break;
        case "20/24-m-16/22":
            fontSize = "md:text-20 md:leading-6 text-16 leading-c22";
            break;
        case "20/24-m-18/24":
            fontSize = "md:text-20 leading-6 text-18";
            break;
        case "20/23":
            fontSize = "text-20 leading-c23";
            break;
        case "17/21":
            fontSize = "text-17 leading-c21";
            break;
        case "16/20":
            fontSize = "md:text-16 text-14 md:leading-5 leading-c17";
            break;
        case "16/20-m-same":
            fontSize = "text-16 leading-5";
            break;
        case "16/20-nav":
            fontSize = "xl:text-16 xl:leading-5 lg:text-14 lg:leading-4 text-16 leading-5";
            break;
        case "16/20-m-14/17":
            fontSize = "lg:text-16 lg:leading-5 text-14 leading-c17";
            break;
        case "16/18":
            fontSize = "text-16 leading-c18";
            break;
        case "18/22":
            fontSize = "text-18 leading-c22";
            break;
        case "14/20":
            fontSize = "text-14 leading-5";
            break;
        case "14/20-m-8/12":
            fontSize = "md:text-14 md:leading-5 text-8 leading-3";
            break;
        case "14/20-m-10/14":
            fontSize = "md:text-14 md:leading-5 text-10 leading-c14";
            break;
        case "14/17":
            fontSize = "text-14 leading-c17";
            break;
        case "15/17":
            fontSize = "text-15 leading-c17";
            break;
        case "14/16":
            fontSize = "text-14 leading-4";
            break;
        case "13/16":
            fontSize = "text-13 leading-4";
            break;
        case "12/15":
            fontSize = "text-12 leading-c15";
            break;
        case "11/13":
            fontSize = "text-11 leading-c13";
            break;
        case "10/12":
            fontSize = "text-10 leading-3";
            break;
        default:
            fontSize = "md:text-16 text-14 md:leading-5 leading-c17";
            break;
    }
    /* =================== [Font Size] =================== */

    return (
        <div
            className={
                `${
                    spacings && "[&_*]:mb-[1em] [&_*]:leading-6 [&_p:first-child]:mb-2 [&_*:last-child]:mb-0 [&_ul]:list-disc [&_ul]:ml-[1em]"
                } ${fontSize} ${
                    strongColor && "[&_strong]:text-dark-blue-standard"
                } ${!customLinkStyles && 'styled-links'}  ${
                    strongSemiBold
                        ? "[&_strong]:font-semibold"
                        : "[&_strong]:font-bold"
                } ` + className
            }
        >
            {children}
        </div>
    );
};

export default FrontText;
